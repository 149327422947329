import { Box, Fade, Stack, Zoom } from "@mui/material";
import pxToRem from "../../../../helpers/pxToRem";
import { RegModalWrapper } from "../ModalContent";
import styles from "../index.module.scss";
import { useEffect, useState } from "react";
import samplePhoto from "../../../../assets/images/teacher-registration/sample-photo-holding-id.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import PhotoUpload from "../../../../components/FileUpload/PhotoUpload";
import { FileType } from "../../../../components/FileUpload";
import { documentUploadHandler } from "./helper";
import { setErrorMsg } from "../../../../redux/uiSlice";
import { v4 as uuidv4 } from "uuid";
import { FieldState } from "../../../../firebase/types-teacher";
import { RegFileUploadLabel } from "../../../../components/FileUpload/RegUploadComponents";


const PhotoHoldingID = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);
    // const [photoUrl, setPhotoUrl] = useState(reg.holdingIdPhotoURL?.value);

    const [file, setFile] = useState<FileType | undefined>(reg.holdingIdPhotoURL?.value ? { file: reg.holdingIdPhotoURL?.value } : undefined);
    const onFileChange = (file: File) => {
        setFile({ file: file, fileName: file.name });
    }

    const isAllComplete = () => {
        return file !== undefined;
    }

    const handleUpdate = async () => {
        try {
            if (!userId) { throw new Error("User is not authenticated. Please refresh and try again.") };
            let updateData: UserUpdate = {}

            const { fileUrl } = await documentUploadHandler(userId, file, { url: reg.holdingIdPhotoURL?.value }) // TODO: Add type and name here

            if (reg.holdingIdPhotoURL && reg.holdingIdPhotoURL.id) {
                if (!fileUrl) {
                    updateData = { teacher: { path: `/identity`, data: { [`${reg.holdingIdPhotoURL.id}`]: null } } }
                } else {
                    updateData = {
                        teacher: {
                            path: `/identity/${reg.holdingIdPhotoURL.id}`,
                            data: {
                                ...(reg.holdingIdPhotoURL.documentType ? null : { documentType: 'photoHolidingIdentification' }),
                                value: fileUrl,
                                state: FieldState.SUBMITTED
                            }
                        }
                    }
                }
            } else if (fileUrl) {
                updateData = {
                    teacher: {
                        path: '/identity',
                        data: {
                            [`${uuidv4()}`]: {
                                documentType: "photoHolidingIdentification",
                                value: fileUrl,
                                state: "submitted"
                            }
                        }
                    }
                }
            }

            dispatch(updateFields(updateData));
        } catch (error: any) {
            dispatch(setErrorMsg(error.message));
        }
    }

    useEffect(() => {
        reg.holdingIdPhotoURL?.value ? setFile({file: reg.holdingIdPhotoURL?.value}) : setFile(undefined);
    }, [reg.holdingIdPhotoURL])

    return (
        <>
            <RegModalWrapper title="Identity Verification - Photo Holding ID" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    holdingIdPhotoURL: reg.holdingIdPhotoURL,
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Photo Holding ID</h5>
                        <p className={styles.p}>Please upload a photo of yourself holding one of your identity documents</p>
                    </Stack>
                    <Stack className={styles.profileContainer}>
                        <Box className={styles['profilePhoto--left']}>
                            <PhotoUpload file={file} onChangeCallback={onFileChange}/>
                            {/*  TODO: Need to alter the text here for UX */}
                            <Fade in={file !== undefined}>
                                <div> <RegFileUploadLabel file={file} reset={() => setFile(undefined)} text={<small></small>} /> </div>
                            </Fade>
                        </Box>
                        <Stack className={styles['profilePhoto--right2']}>
                            <small style={{ alignSelf: 'flex-end', color: styles.shades100 }}>Example*</small>
                            <img src={samplePhoto} alt={'Holding ID sample'} />
                        </Stack>
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default PhotoHoldingID;