import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

export type TscreenType = "PC" | "TABLET" | "MOBILE";

const useGetScreenType = (): TscreenType => {
  const [screenType, setScreenType] = useState<TscreenType>("PC");
  const isTablet = useMediaQuery("(max-width: 1240px)");
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if(isMobile) {
      setScreenType("MOBILE");
    } else if (isTablet) {
      setScreenType("TABLET");
    } else {
      setScreenType("PC");
    }
  }, [isMobile, isTablet]);

  return screenType;
};

export default useGetScreenType;