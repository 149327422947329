export const DEFAULT_FILESIZE_LIMIT = 10 //MB

export const getMimeTypes = (extensions?: string[]): string[] => {
    if (!extensions) { return [] }
    let mimeTypes:string[] = [];
    for(let ex of extensions) {
        switch(ex) {
            case 'jpeg':case 'jpg': mimeTypes.push('image/jpeg'); break;
            case 'png': mimeTypes.push('image/png'); break;
            case 'pdf': mimeTypes.push('application/pdf');
        }
    }
    return mimeTypes;
}

export const getObjectURL = (file: any): null | string => {
    let url = null;
    if ((window as any).createObjcectURL !== undefined) {
      url = (window as any).createOjcectURL(file);
    } else if (window.URL !== undefined) {
      url = window.URL.createObjectURL(file);
    } else if (window.webkitURL !== undefined) {
      url = window.webkitURL.createObjectURL(file);
    }
    return url;
  };