import styles from "./index.module.scss";
import Stack from "@mui/material/Stack";
import SparkLogoImg from "../../assets/images/header/Spark Relief Teachers Logo.png";
import {
  HEADER_BUTTON_CONFIGS,
  HEADER_DROPDOWN_CONFIGS,
  HEADER_DROPDOWN_LOGIN_CONFIGS,
  HEADER_DROPDOWN_PRELOGIN_CONFIGS
} from "./constants";
import HeaderButton, { DropdownHeaderButton } from "./components/HeaderButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { TabNames } from "../../routers/type";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import { useEffect, useMemo, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HideOnScroll from "../HideOnScroll";
import pxToRem from "../../helpers/pxToRem";
import { Skeleton } from "@mui/material";

interface IHeader {
  showShopCart?: boolean;
}

const Header = ({ showShopCart }: IHeader) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const screenType = useSelector((state: RootState) => state.ui.screenType);
  const shopCartItems = useSelector((state: RootState) => state.user.shopCart);
  const userId = useSelector((state: RootState) => state.user.userId);

  // const isLogined = useMemo(() => Boolean(typeof userId === 'string'),[userId])
  const trigger = useScrollTrigger();
  const location = useLocation();
  const [shopcartShrinking, setShopcartShrinking] = useState(false);
  const shotCartItemsCount = useMemo(() => {
    return shopCartItems.length > 0
      ? shopCartItems
        .map((shopCartItem) => shopCartItem.orderCount)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
      : 0;
  }, [shopCartItems]);
  // const loginStatusIsLoading = useSelector(
  //   (state: RootState) => state.user.loginStatusIsLoading
  // );


  useEffect(() => {
    setShopcartShrinking(true);
    setTimeout(() => setShopcartShrinking(false), 2000);
  }, [shotCartItemsCount]);

  return (
    <HideOnScroll>
      <AppBar
        // id={is_fixed || !isLargeScreenSize ? "header--isFixed" : "header--notFixed"}
        sx={{ transition: "linear 0.3s" }}
        // color={is_fixed || !isLargeScreenSize ? "default" : "transparent"}
        className={styles.Header__appBar}
      >
        <Stack
          width={"100%"}
          justifyContent='space-between'
          alignItems={"center"}
        >
          <Stack
            direction='row'
            justifyContent={"space-between"}
            alignItems='center'
            className={styles.header_buttons_stack}
          >
            <Stack direction='row' className={styles.container}>
              <IconButton
                sx={{ display: screenType === "PC" ? "none" : "unset" }}
                onClick={() => setDropdownVisible((prestate) => !prestate)}
              >
                <MenuIcon className={styles.menuButton} />
              </IconButton>
              <img
                className={styles.logo}
                src={SparkLogoImg}
                onClick={() => navigate("/")}
                alt='Spark Relief Teachers Logo'
              />
              {showShopCart && (
                <IconButton
                  className={`${styles.smallShopCartButton} ${shopcartShrinking ? "rotate_animation" : ""
                    }`}
                  onClick={() =>
                    navigate(`/${TabNames.SHOP}/${TabNames.CHECK_OUT}`)
                  }
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 24 24'
                    className={styles.shopCartIcon}
                  >
                    <g>
                      <path d='M17.437,19.934c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1Zm-11.217,-4.266l-0.945,-10.9c-0.03,-0.391 -0.356,-0.693 -0.749,-0.693l-0.966,-0c-0.276,-0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5l0.966,-0c0.916,-0 1.676,0.704 1.746,1.617l0.139,1.818l13.03,-0c0.885,-0 1.577,0.76 1.494,1.638l-0.668,7.52c-0.121,1.285 -1.199,2.267 -2.489,2.267l-9.069,0c-1.29,0 -2.367,-0.981 -2.489,-2.267Zm0.274,-8.158l0.722,8.066c0.073,0.77 0.719,1.359 1.493,1.359l9.069,0c0.774,0 1.42,-0.589 1.493,-1.359l0.668,-7.518c0.028,-0.294 -0.203,-0.548 -0.498,-0.548l-12.947,-0Zm4.454,12.424c-0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c-0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1Z' />
                    </g>
                  </svg>
                  <div
                    className={`${styles.shopCartCount} label_large semi_bold_weight`}
                  >
                    {shotCartItemsCount}
                  </div>
                </IconButton>
              )}
              <Stack
                direction='row'
                className={styles.headerButton_stack}
                sx={{ display: screenType === "PC" ? "flex" : "none" }}
              >
                {/* <MenuHeaderButton text={"Teachers"} link={TabNames.TEACHERS} config={TEACHER_STATE_PAGES}/> */}
                {/* <MenuHeaderButton text={"Schools"} link={TabNames.SCHOOLS} config={SCHOOL_STATE_PAGES}/> */}

                {HEADER_BUTTON_CONFIGS.map((config) => (
                  <HeaderButton key={config.text} {...config} />
                ))}
              </Stack>
            </Stack>
            <Popper
              open={dropdownVisible}
              role={undefined}
              placement='bottom-start'
              transition
              disablePortal
              className={styles.dropdownHeader_menuList}
              sx={{ zIndex: "2" }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper
                    className={styles.dropdownHeader__paper}
                    sx={{
                      backgroundColor: "#f5f5f5",
                      boxShadow: "none",
                    }}
                  >
                    <MenuList aria-labelledby='composition-button'>
                      {HEADER_DROPDOWN_CONFIGS.map((config) => (
                        // <MenuItem className={styles.menuItem}>
                        <DropdownHeaderButton
                          {...config}
                          isSelected={config.link === location.pathname}
                          handleClose={() => setDropdownVisible(false)}
                        />
                        // </MenuItem>
                      ))}
                      {userId === undefined
                        ? null
                        : (userId !== null
                          ? HEADER_DROPDOWN_LOGIN_CONFIGS
                          : HEADER_DROPDOWN_PRELOGIN_CONFIGS
                        ).map((config) => (
                          // <MenuItem className={styles.menuItem}>
                          <DropdownHeaderButton
                            {...config}
                            isSelected={config.link === location.pathname}
                          />
                          // </MenuItem>
                        ))}
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Stack
              sx={{ display: screenType === "PC" ? "flex" : "none" }}
              direction='row'
              alignItems={"center"}
              className={styles.header_leftStack}
            >
              {userId === undefined ?
                <Skeleton height={pxToRem(60)} width={pxToRem(125)} sx={{borderRadius: '22px'}}/>
                : userId !== null ? (
                  <Stack direction='row' gap={pxToRem(20)}>
                    <Button className={`${styles.dashboardButton} bold_weight title_medium`} onClick={() => navigate(`/${TabNames.DASHBOARD}`)} >
                      <Box>Dashboard</Box>
                    </Button>
                  </Stack>
                ) : (
                  <>
                    <Button className={`${styles.loginButton} medium_weight label_large`} onClick={() => { navigate(`/${TabNames.LOGIN}`); }} >
                      <Stack className={styles.loginLogoutButtonContainer}>
                        <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg' > <path fillRule='evenodd' clipRule='evenodd' d='M9 2C4.86 2 1.5 5.36 1.5 9.5C1.5 13.64 4.86 17 9 17C13.14 17 16.5 13.64 16.5 9.5C16.5 5.36 13.14 2 9 2ZM13.77 13.1225C12.6975 11.8175 10.095 11.375 9 11.375C7.905 11.375 5.3025 11.8175 4.23 13.1225C3.465 12.1175 3 10.865 3 9.5C3 6.1925 5.6925 3.5 9 3.5C12.3075 3.5 15 6.1925 15 9.5C15 10.865 14.535 12.1175 13.77 13.1225ZM6.375 7.625C6.375 6.17 7.545 5 9 5C10.455 5 11.625 6.17 11.625 7.625C11.625 9.08 10.455 10.25 9 10.25C7.545 10.25 6.375 9.08 6.375 7.625Z' fill='#191C1E' /> </svg>
                        <Box>Login</Box>
                      </Stack>
                    </Button>
                    <Button className={`${styles.signupButton} medium_weight label_large`} onClick={() => { navigate(`/${TabNames.REGISTER}`); }} >
                      <Stack className={styles.loginLogoutButtonContainer}>
                        <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg' > <path d='M11.8594 9.51562H2.82861' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /> <path d='M9.66357 7.32812L11.8596 9.51512L9.66357 11.7021' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /> <path d='M6.3783 6.04088V5.34113C6.3783 3.81487 7.61505 2.57812 9.14205 2.57812H12.805C14.3275 2.57812 15.5613 3.81187 15.5613 5.33437V13.6894C15.5613 15.2156 14.3238 16.4531 12.7975 16.4531H9.1338C7.61205 16.4531 6.3783 15.2186 6.3783 13.6969V12.9904' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /> </svg>
                        <Box>Register</Box>
                      </Stack>
                    </Button>
                  </>
                )}
              {showShopCart && (
                <IconButton className={`${styles.shopCartButton} ${shopcartShrinking ? "rotate_animation" : ""}`} onClick={() => navigate(`/${TabNames.SHOP}/${TabNames.CHECK_OUT}`)} >
                  {/* <ShoppingOutlined className={styles.shopCartIcon} /> */}
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className={styles.shopCartIcon} > <g> <path d='M17.437,19.934c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1Zm-11.217,-4.266l-0.945,-10.9c-0.03,-0.391 -0.356,-0.693 -0.749,-0.693l-0.966,-0c-0.276,-0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5l0.966,-0c0.916,-0 1.676,0.704 1.746,1.617l0.139,1.818l13.03,-0c0.885,-0 1.577,0.76 1.494,1.638l-0.668,7.52c-0.121,1.285 -1.199,2.267 -2.489,2.267l-9.069,0c-1.29,0 -2.367,-0.981 -2.489,-2.267Zm0.274,-8.158l0.722,8.066c0.073,0.77 0.719,1.359 1.493,1.359l9.069,0c0.774,0 1.42,-0.589 1.493,-1.359l0.668,-7.518c0.028,-0.294 -0.203,-0.548 -0.498,-0.548l-12.947,-0Zm4.454,12.424c-0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c-0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1Z' /> </g> </svg>
                  <div className={`${styles.shopCartCount} title_medium semi_bold_weight`} > {shotCartItemsCount} </div>
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </AppBar>
    </HideOnScroll>
  );
};

export default Header;