import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "../redux/store";
import { useMemo } from "react";
import { SchoolAccountState, SchoolUserAccountState, SchoolUserAuthority } from "../firebase/types-school";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import { TabNames } from "./type";
import { auth } from "../firebase/configs";

interface IRoute {
  children: JSX.Element;
  customizedFallbackRoute?: string;
}

export function AuthRoute({ children, customizedFallbackRoute }: IRoute) {
  const userId = useSelector((state: RootState) => state.user.userId);

  if(userId === undefined) return <LoadingSpinner fillViewPortHeight fillViewPortWidth/>

  return userId !== null ? (
    <Navigate to={`${customizedFallbackRoute ?? "/"}`} replace />
  ) : (
    children
  );
}

export const VerifyWrapper = ({ children }: IRoute) => {
  const user = useSelector((state: RootState) => state.user.user);

  if(user === undefined || !auth.currentUser) return (<LoadingSpinner/>)

  if(user === null) { return <Navigate to={`/${TabNames.LOGIN}`} replace/>}

  // if(localStorage.getItem('payslipReq') !== null) {
  //   <Navigate to={`/${TabNames.PAYSLIP_API}/${localStorage.getItem('payslipReq')}`} />
  // }

  return auth.currentUser.emailVerified ? 
    <Navigate to={`/${TabNames.DASHBOARD}`} replace/>
  : children
}

export function DashboardRoute({ children, customizedFallbackRoute }: IRoute) {
  const userDelete = useSelector((state: RootState) => state.userReg.userDelete);
  const sessionLogout = useSelector((state: RootState) => state.user.sessionLogout);
  const user = useSelector((state: RootState) => state.user.user);


  if (user === undefined) {
    return <></>
  }
  
  if(user === null) {
    if(userDelete || sessionLogout) {
      return children;
    }
    return <Navigate to={customizedFallbackRoute || `/`} replace />
  }

  if (user.accountType === undefined) {
    return <Navigate to={`/${TabNames.CHOOSE_ACC_TYPE}`} replace />
  }

  if(auth.currentUser && !auth.currentUser.emailVerified) {
    return <Navigate to={`/${TabNames.VERIFY_EMAIL}`} replace/>
  }

  return children;

  // return userDelete || sessionLogout ? (
  //   children
  // ) : (
  //   <Navigate to={`${customizedFallbackRoute ?? `/${TabNames.LOGIN}`}`} replace />
  // );
}

export const SchoolPermissionRoute = ({ children, customizedFallbackRoute }: IRoute) => {
  const schoolId = useSelector((state: RootState) => state.school.schoolId);
  const school = useSelector((state: RootState) => state.school.school);
  const schoolUser = useSelector((state: RootState) => state.school.schoolUser);
  
  const canAccessMySchool = useMemo(() => {
    if (!schoolId || !school?.account?.state || !schoolUser.school?.accountState || !schoolUser.school?.authority) return undefined;
    switch (school?.account?.state as SchoolAccountState) {
      case SchoolAccountState.APPROVED: case SchoolAccountState.PENDING: {
        if (schoolUser.school?.accountState === SchoolUserAccountState.APPROVED || schoolUser.school?.authority === SchoolUserAuthority.ADMIN) {
          return true;
        }
        return false;
      }
      case SchoolAccountState.REMOVED: {
        return false;
      }
      default: return false;
    }
  }, [school?.account?.state, schoolUser.school?.accountState, schoolUser.school?.authority])
  return canAccessMySchool === undefined ?
    <LoadingSpinner />
    :
    canAccessMySchool ?
      children
      :
      <Navigate to={`${customizedFallbackRoute ?? "/"}`} replace />
}