import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { shopCartItemsEqual } from "../helpers/shopCartHelpers";
import { clearShopcartFromStorage } from "./helpers";
import { ShopCart, UserType } from "./types";

interface IUserSlice {
  loginStatusIsLoading: boolean;
  emailVerified: boolean;
  userId?: string | null;
  userType: string | null;
  accountState: string | null;
  shopCart: ShopCart[];
  dbStock: { [stripeId: string]: { count: number } };
  user?: UserType | null;
  userEmpty?: boolean;
  sessionLogout?: boolean;

  homeState?: string;

}

export const initialState: IUserSlice = {
  loginStatusIsLoading: true,
  emailVerified: true,
  // userId: null,
  userType: null,
  accountState: null,
  shopCart: [],
  dbStock: {}
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginStatusIsLoading: (state, action: PayloadAction<boolean>) => {
      state.loginStatusIsLoading = action.payload;
    },
    setUser:(state, action: PayloadAction<UserType | null>) => {
      state.user = action.payload;
    },
    setUserHomeState: (state, action: PayloadAction<string>) => {
      state.homeState = action.payload;
    },
    setUserEmpty:(state, action: PayloadAction<boolean|undefined>) => {
      state.userEmpty = action.payload;
    },
    setUserLogout: (state) => {
      state.emailVerified = false;
      state.userType = null;
      state.accountState = null;
      state.dbStock = {};
      state.user = null;
      state.userId = null;
    },
    setEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.emailVerified = action.payload;
    },
    setUserId: (state, action: PayloadAction<string|null>) => {
      state.userId = action.payload;
    },
    setUserType: (state, action: PayloadAction<string | null>) => {
      state.userType = action.payload;
    },
    setAccountState: (state, action: PayloadAction<string>) => {
      state.accountState = action.payload;
    },
    setSessionLogout: (state, action: PayloadAction<boolean>) => {
      state.sessionLogout = action.payload;
    },

    addToShopCart: (state, action: PayloadAction<ShopCart>) => {
      const existedItem = state.shopCart.find((item) =>
        shopCartItemsEqual(item, action.payload)
      );

      if (existedItem) {
        existedItem.orderCount =
          existedItem.orderCount + action.payload.orderCount;
        state.shopCart = [...state.shopCart];
      } else {
        state.shopCart.push(action.payload);
        state.shopCart = [...state.shopCart];
      }
    },
    deleteFromShopCart: (state, action: PayloadAction<ShopCart>) => {
      state.shopCart = state.shopCart.filter(
        (item) => !shopCartItemsEqual(item, action.payload)
      );
    },
    clearShopCart: (state) => {
      state.shopCart = [];
      clearShopcartFromStorage();
    },
    cacheDbStock: (
      state,
      action: PayloadAction<{
        stripeId: string;
        orderCount: number;
      }>
    ) => {
      if (
        typeof state.dbStock[action.payload.stripeId] === "number" &&
        state.dbStock[action.payload.stripeId].count ===
          action.payload.orderCount
      )
        return;
      state.dbStock = {
        ...state.dbStock,
        [action.payload.stripeId]: {
          count: action.payload.orderCount,
        },
      };
    },
  },
});

export const {
  setUser,
  setEmailVerified,
  setUserType,
  setUserHomeState,
  setUserLogout,
  setAccountState,
  setUserId,
  addToShopCart,
  deleteFromShopCart,
  cacheDbStock,
  clearShopCart,
  setLoginStatusIsLoading,
  setUserEmpty,
  setSessionLogout
} = userSlice.actions;

export default userSlice.reducer;
