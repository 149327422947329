import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccreditationDocumentDetails, LocalTeacherEmploymentSetting, TeacherFields, TeacherNotifications, TeacherProfile } from "./types";

export interface UserUpdate {
  user?: {path?: string, data: any};
  teacher?: {path?: string, data: any};
  // path:{userPath?:string, teacherPath?: string};
  // data:{userData?: any, teacherData?: any};
}

interface IUserRegSlice {
    fields: TeacherFields;
    profile: TeacherProfile;
    stateTeachingDocuments?: {[locationState:string]: AccreditationDocumentDetails[] | null} | null;
    stateECCDocuments?: {[locationState:string]: AccreditationDocumentDetails[] | null} | null;
    notifications: TeacherNotifications,
    step: number;
    stateList: string[],
    fromEdit?: string;
    openRegModal: boolean;
    openProfileModal: boolean;
    userUpdate?: UserUpdate;
    incompleteState?: string;
    profilePhotoStep?:boolean;
    // firstTime: boolean;
    userDelete?: boolean;

    locationState?: string;
    employmentSetting?: LocalTeacherEmploymentSetting;  
}

export const initialState: IUserRegSlice = {
  fields: {},
  profile: {},
  notifications: {},
  step: 0,
  stateList: [],
  openRegModal: false,
  openProfileModal: false,
  // firstTime: false,
};

export const userRegSlice = createSlice({
  name: "userReg",
  initialState,
  reducers: {
    setUserRegDefault: (state) => {
        state.fields = initialState.fields;
        state.profile = initialState.profile;
        state.stateTeachingDocuments = initialState.stateTeachingDocuments;
        state.notifications = initialState.notifications;
        state.step = initialState.step;
        state.openProfileModal = false;
        state.openRegModal = false;

        state.incompleteState = undefined;
        state.userUpdate = undefined;
        state.profilePhotoStep = undefined;
        state.fromEdit = undefined;

        state.locationState = undefined;
        state.employmentSetting = undefined;
    },
    setRegFields: (state, action: PayloadAction<TeacherFields>) => {
        state.fields = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setStateList: (state, action: PayloadAction<string[]>) => {
      state.stateList = action.payload;
    },
    updateFields: (state, action: PayloadAction<UserUpdate|undefined>) => {
      state.userUpdate = action.payload;
    },
    setFromEdit: (state, action: PayloadAction<string|undefined>) => {
      state.fromEdit = action.payload;
    },
    setIncompleteState: (state, action: PayloadAction<string>) => {
      state.incompleteState = action.payload;
    },
    setOpenRegModal: (state, action: PayloadAction<boolean>) => {
      state.openRegModal = action.payload;
    },
    setOpenProfileModal: (state, action: PayloadAction<boolean>) => {
      state.openProfileModal = action.payload;
    },
    setTeacherProfile: (state, action: PayloadAction<TeacherProfile>) => {
      state.profile = action.payload;
    },
    setTeacherNotifications: (state, action: PayloadAction<TeacherNotifications>) => {
      state.notifications = action.payload
    },
    setProfilePhotoStep: (state, action: PayloadAction<boolean|undefined>) => {
      state.profilePhotoStep = action.payload;
    },
    // setFirstTime: (state, action: PayloadAction<boolean>) => {
    //   state.firstTime = action.payload;
    // },
    setUserDelete: (state, action: PayloadAction<boolean>) => {
      state.userDelete = action.payload;
    },
    setStateTeachingDocument: (state, action: PayloadAction<{[locationState:string]: AccreditationDocumentDetails[] | null}|null>) => {
      state.stateTeachingDocuments = action.payload;
    },
    setStateECCTeachingDocument: (state, action: PayloadAction<{[locationState:string]: AccreditationDocumentDetails[] | null}|null>) => {
      state.stateECCDocuments = action.payload;
    },

    setTeacherLocationState: (state, action: PayloadAction<string|undefined>) => {
      state.locationState = action.payload;
    },
    setEmploymentSetting: (state, action: PayloadAction<LocalTeacherEmploymentSetting|undefined>) => {
      state.employmentSetting = action.payload;
    }
  },
});

export const {
    setUserRegDefault,
    setRegFields,
    setStep,
    setStateList,
    updateFields,
    setFromEdit,
    setIncompleteState,
    setOpenRegModal,
    setOpenProfileModal,
    setTeacherProfile,
    setTeacherNotifications,
    setProfilePhotoStep,
    // setFirstTime,
    setUserDelete,
    setStateTeachingDocument,
    setStateECCTeachingDocument,

    setTeacherLocationState,
    setEmploymentSetting
} = userRegSlice.actions;

export default userRegSlice.reducer;
